var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null ? _vm.$t("students_create") : _vm.$t("students_edit"),
      },
    },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:150",
                  expression: "'required|max:150'",
                },
              ],
              staticClass: "w-full",
              attrs: { label: _vm.$t("name"), name: "nome", maxlength: "150" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("nome"),
                    expression: "errors.has('nome')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("nome")))]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/5" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("birthdate")))]),
            _c("datetime", {
              attrs: {
                "input-class": "vs-inputx vs-input--input normal",
                type: "date",
                format: "dd/MM/yyyy",
                "value-zone": "America/Sao_Paulo",
                "use24-hour": "",
              },
              model: {
                value: _vm.birthdate,
                callback: function ($$v) {
                  _vm.birthdate = $$v
                },
                expression: "birthdate",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("birthdate"),
                    expression: "errors.has('birthdate')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("birthdate")))]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col sm:w-1/5" }, [
          _c(
            "div",
            { staticClass: "centerx" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("gender")))]),
              _c("br"),
              _c(
                "vs-radio",
                {
                  attrs: { "vs-value": "feminine" },
                  model: {
                    value: _vm.gender,
                    callback: function ($$v) {
                      _vm.gender = $$v
                    },
                    expression: "gender",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("feminine")) + " ")]
              ),
              _c(
                "vs-radio",
                {
                  attrs: { "vs-value": "male" },
                  model: {
                    value: _vm.gender,
                    callback: function ($$v) {
                      _vm.gender = $$v
                    },
                    expression: "gender",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("male")) + " ")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("gender"),
                      expression: "errors.has('gender')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("gender")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              staticClass: "w-full",
              attrs: {
                "data-vv-validate-on": "blur",
                label: _vm.$t("email"),
                id: "email",
                name: "email",
                type: "email",
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
            _c("span", { staticClass: "text-danger text-sm" }, [
              _vm._v(_vm._s(_vm.errors.first("email"))),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { type: "border" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$t("action.cancel")))]
            ),
            _c(
              "vs-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "students.create",
                    expression: "'students.create'",
                  },
                ],
                staticClass: "float-right mr-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.create },
              },
              [_vm._v(_vm._s(_vm.$t("action.save")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }